body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


table,tr,th,td{border:none}
 .table{ width: 100%; display: table;}
 .table tbody>tr:nth-child(odd)>th,
 .table > tbody > tr:nth-child(odd) > td{background: #f9f9f9}
 .table tbody > tr > td,
 .table tbody > tr > td{border-top:1px solid #ddd;}
 th{text-align:left; color: #688a7e;    font-weight: bold;}
 .table > tbody > tr:hover > td, .table > tbody > tr:hover > th{background: #f5f5f5}

 /*ekranın hangi boyutlarında devreye gireceğini belirliyoruz*/
 @media 
 only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

    table, thead, tbody, th, td, tr { 
        display: block; 
    }
    /*tablomuzun thead gizliyoruz*/
    .table thead tr { 
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .table tr { border: 1px solid #ccc; }

    .table td { 
        border: none;
        border-bottom: 1px solid #eee; 
        position: relative;
        padding-left: 50%!important; 
    }
    /*Burada td:before data-title ların css'i oluyor*/
    .table td:before { 
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%; 
        padding-right: 10px; 
        white-space: nowrap;
        font-weight:bold;
    }
   /*data-title valulerini alarak sütünümuza eklemiş oluyoruz*/
  .table td:before { content: attr(data-title);
 }
}


/* App.css */

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, #1e90ff, #00c6ff);
}

.hero-btn {
  transition: transform 0.3s;
}

.hero-btn:hover {
  transform: scale(1.05);
}

/* Features Section */
.features-section .feature-card:hover {
  background-color: #f8f9fa;
  transform: translateY(-10px);
  transition: transform 0.3s, background-color 0.3s;
}

/* How It Works Section */
.how-it-works-section .how-it-works-card:hover {
  background-color: #e9ecef;
  transform: translateY(-10px);
  transition: transform 0.3s, background-color 0.3s;
}

/* Testimonials Section */
.testimonials-section .testimonial-card {
  background: #f8f9fa;
  border-left: 4px solid #007bff;
  border-bottom: 4px solid #007bff;
}

.testimonials-section .testimonial-card:hover {
  transform: translateY(-10px);
  transition: transform 0.3s;
}

/* Call to Action Section */
.call-to-action-section .call-to-action-btn:hover {
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s, color 0.3s;
}

/* Footer Section */
.footer-section {
  background-color: #343a40;
}
